<template>
  <div :class="[isOpen ? 'is-active' : 'is-inactive']" class="kb-form-period-date dropdown" data-id="dropdown-calendar-picker">
    <button class="kb-btn kb-btn-outline rounded-lg dropdown-btn" @click="isOpen = !isOpen">
      <span class="text">입실 {{ startDt ? ymdStrToDateFormat(startDt.ymd, 'yyyy.MM.dd') : `날짜 선택` }}</span>
    </button>
    <div class="dropdown-target">
      <div class="dropdown-box">
        <!-- calendar-picker-container -->
        <div class="calendar-picker-container">
          <!-- calendar-picker-container > calendar-picker -->
          <div class="calendar-picker">
            <div class="picker-body">
              <div class="datepicker-header">
                <button class="kb-btn-datepicker-nav kb-btn-datepicker-prev" @click="changeMonth(false)"><i class="icon-prev"></i></button>
                <div class="title" @click="changeMonth(false)">{{ currentYearMonth }}</div>
              </div>
              <div class="datepicker-table datepicker-table-week">
                <table>
                  <thead>
                  <tr>
                    <th>일</th>
                    <th>월</th>
                    <th>화</th>
                    <th>수</th>
                    <th>목</th>
                    <th>금</th>
                    <th>토</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(week, idx) in currentWeeks" :key="idx">
                      <td v-for="(day, i) in week" :key="`${idx}-${i}`">
                        <template v-if="day.month === currentMonth">
                          <button class="kb-btn-day" :class="{ 'is-active' : startDt.ymd === day.ymd || endDt.ymd === day.ymd }" @click="handleSelectDate(day)">
                            <span class="text">{{ day.day }}</span></button>
                        </template>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- calendar-picker-container > calendar-picker -->
          <div class="calendar-picker">
            <div class="picker-body">
              <div class="datepicker-header">
                <div class="title" @click="changeMonth(true)">{{ nextYearMonth }}</div>
                <button class="kb-btn-datepicker-nav kb-btn-datepicker-next" @click="changeMonth(true)"><i
                    class="icon-next"></i>
                </button>
              </div>
              <div class="datepicker-table datepicker-table-week">
                <table>
                  <thead>
                  <tr>
                    <th>일</th>
                    <th>월</th>
                    <th>화</th>
                    <th>수</th>
                    <th>목</th>
                    <th>금</th>
                    <th>토</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(week, idx) in nextWeeks" :key="idx">
                    <td v-for="(day, i) in week" :key="`${idx}-${i}`">
                      <template v-if="day.month === nextMonth">
                        <button class="kb-btn-day" :class="{ 'is-active' : startDt.ymd === day.ymd || endDt.ymd === day.ymd }" @click="handleSelectDate(day)">
                          <span class="text">{{ day.day }}</span></button>
                      </template>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- //calendar-picker-container -->
      </div>
    </div>
  </div>
  <div class="kb-form-period-date dropdown" data-id="dropdown-calendar-picker">
    <button class="kb-btn kb-btn-outline rounded-lg dropdown-btn"
            :class="{ 'is-active' : isActiveCheckOutDate }"
            @click="isOpen = !isOpen">
      <span class="text">퇴실 {{ endDt ? ymdStrToDateFormat(endDt.ymd, 'yyyy.MM.dd') : `날짜 선택` }}</span>
    </button>
  </div>
</template>

<script>
import {computed, reactive, ref, watch} from 'vue';
import {dateToDateFormat, getCalendar, ymdStrToDate, ymdStrToDateFormat} from '@/assets/js/util';

export default {
  name: 'KbDatepickerRange',
  props: {
    modelValue: {
      type: Object,
      default: () => {
        return {
          startDt: '',
          endDt: ''
        };
      },
    }
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const currentDate = new Date();
    // 현재 연월일 데이트 피커 데이터
    const currentYear = ref(currentDate.getFullYear());
    const currentMonth = ref(currentDate.getMonth() + 1);
    // const currentDay = ref(currentDate.getDate());

    // 오늘 연 월 일
    const today = reactive({
      year: currentDate.getFullYear(),
      month: (currentDate.getMonth() + 1),
      day: currentDate.getDate(),
    });

    // 이번주 데이터
    const currentWeeks = computed(() => getCalendar(currentYear.value, currentMonth.value));

    // Datepicker range 일때 두번째 달력 뿌릴 데이터
    // 다음 달이 내년일 경우 연도+1 아니면 이번년도
    const getNextYear = computed(() => {
      if (currentMonth.value === 12) {
        return currentYear.value + 1;
      } else {
        return currentYear.value;
      }
    });

    // 다음달이 내년일 경우 1월 아니면 다음달
    const getNextMonth = computed(() => {
      if (currentMonth.value === 12) {
        return 1;
      } else {
        return currentMonth.value + 1;
      }
    });

    const nextYear = ref(getNextYear.value);
    const nextMonth = ref(getNextMonth.value);

    // 2번째 달력의 주 정보
    const nextWeeks = computed(() => getCalendar(getNextYear.value, getNextMonth.value));

    const isOpen = ref(false);
    // const isLoading = ref(false);

    const todayYmd = `${today.year}${('0' + today.month).slice(-2)}${('0' + today.day).slice(-2)}`;

    const startDt = ref('');
    const endDt = ref('');

    const currentYearMonth = computed(() => {
      if (currentMonth.value === 12) {
        return `${currentYear.value + 1}년 1월`;
      } else {
        return `${currentYear.value}년 ${currentMonth.value}월`;
      }
    });

    const nextYearMonth = computed(() => {
      if (currentMonth.value === 12) {
        return `${currentYear.value + 1}년 1월`;
      } else {
        return `${currentYear.value}년 ${currentMonth.value + 1}월`;
      }
    });

    const prevYearMonth = computed(() => {
      if (currentMonth.value === 1) {
        return `${currentYear.value - 1}년 12월`;
      } else {
        return `${currentYear.value}년 ${currentMonth.value - 1}월`;
      }
    });

    // 다음, 이전 연 월 버튼 기능
    const changeMonth = (isNext) => {
      if (isNext) {
        if (currentMonth.value === 12) {
          currentYear.value++;
          currentMonth.value = 1;
        } else {
          currentMonth.value++;
        }

        if (nextMonth.value === 12) {
          nextYear.value++;
          nextMonth.value = 1;
        } else {
          nextMonth.value++;
        }
      } else {
        if (currentMonth.value === 1) {
          currentYear.value--;
          currentMonth.value = 12;
        } else {
          currentMonth.value--;
        }

        if (nextMonth.value === 1) {
          nextYear.value--;
          nextMonth.value = 12;
        } else {
          nextMonth.value--;
        }
      }
    };

    const init = () => {
      currentYear.value = currentDate.getFullYear();
      currentMonth.value = currentDate.getMonth() + 1;
      nextYear.value = getNextYear.value;
      nextMonth.value = getNextMonth.value;
    };

    const isActiveCheckOutDate = ref(false);

    const handleSelectDate = (date) => {
      if (!startDt.value) {
        startDt.value = date;
      } else if (startDt.value && ymdStrToDate(startDt.value.ymd) > ymdStrToDate(date.ymd) || startDt.value && endDt.value) {
        startDt.value = date;
        endDt.value = '';
      } else if (startDt.value && ymdStrToDate(startDt.value.ymd) < ymdStrToDate(date.ymd)) {
        endDt.value = date;
      }
    };

    const isPastDay = (date) => {
      if (currentDate < ymdStrToDate(date.ymd)) return false;
      return true;
    };

    watch(() => isOpen.value, () => {
      // 열고 닫을때 오늘 기준 월 보여주기 위해 init()
      if (isOpen.value) {
        init();
        if (startDt.value) {
          isActiveCheckOutDate.value = true;
        }
      } else {
        isActiveCheckOutDate.value = false;
      }
    });

    watch(() => [startDt.value, endDt.value], () => {
      if (startDt.value) {
        isActiveCheckOutDate.value = true;
      } else {
        isActiveCheckOutDate.value = false;
      }

      if (startDt.value && endDt.value) {
        isActiveCheckOutDate.value = false;
        isOpen.value = false;
      }
      emit('update:modelValue',{ startDt: startDt.value, endDt: endDt.value });
    });

    watch(() => props.modelValue, () => {
      if (props.modelValue) {
        if (!props.modelValue?.startDt) {
          startDt.value = '';
        }
        if (!props.modelValue?.endDt) {
          endDt.value = '';
        }
      }
    });

    const initDate = () => {
      startDt.value = '';
      endDt.value = '';
    }

    return {
      ymdStrToDate,
      dateToDateFormat,
      ymdStrToDateFormat,

      today,
      isOpen,

      currentYear,
      currentMonth,
      // currentDay,
      currentWeeks,

      nextYear,
      nextMonth,
      nextWeeks,

      todayYmd,
      currentYearMonth,
      nextYearMonth,
      prevYearMonth,
      changeMonth,

      handleSelectDate,
      isActiveCheckOutDate,

      isPastDay,

      startDt,
      endDt,
      initDate
    };
  },
};
</script>
